import {
  ensureInitialized,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';
import app from './firebase';

export const initializeRemoteConfig = async (): Promise<void> => {
  const remoteConfig = getRemoteConfig(app);
  ensureInitialized(remoteConfig).then(() => {
    fetchAndActivate(remoteConfig);
  });
};

const parseValue = (value: string): string | number | boolean | Record<string, unknown> => {
  try {
    const parsed = JSON.parse(value);
    if (typeof parsed === 'boolean' || typeof parsed === 'number' || typeof parsed === 'object') {
      return parsed;
    }
  } catch (e) {
    return value;
  }

  return value;
};

export const getRemoteConfigValue = (
  key: string
): string | number | boolean | Record<string, unknown> => {
  const value = getValue(getRemoteConfig(app), key).asString();
  return parseValue(value);
};
