import { useAuthenticationService, useFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { projectName } from '../domain/constants/feature-toggle';

export const useFeatureToggleWithCountry = (
  flagName: string,
  country: string
): [boolean, boolean] => {
  const userId = useAuthenticationService().getUserId();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setValue(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFeatureToggleServiceV2(projectName)
      .onReady()
      .then(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useFeatureToggleServiceV2(projectName)
          .isFeatureEnabledAudiences(flagName, userId, { country })
          .then((res) => {
            setValue(res);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  }, [country, flagName, userId]);

  return [value, isLoading];
};
