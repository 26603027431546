import { TCountryLocale } from 'shared/domain/contracts/interfaces/ICountries';

type TDate = number | Date | string | undefined;

interface FormattedDeliveryDateByLocaleProps {
  date: TDate | undefined;
  locale: TCountryLocale | undefined;
}

const formatDate = (date: Date, locale = 'en-US'): string => {
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();

  date.setDate(day);
  date.setFullYear(year);
  date.setMonth(month);

  const shortMonthName = date.toLocaleString(locale, { month: 'short' });
  const longDayName = date.toLocaleString(locale, { weekday: 'long' });

  return `${longDayName}, ${shortMonthName} ${day}`;
};

const formattedDeliveryDateByLocale = ({
  date,
  locale,
}: FormattedDeliveryDateByLocaleProps): string => {
  if (!locale || !date) {
    return '';
  }

  return formatDate(new Date(date), locale);
};

export default formattedDeliveryDateByLocale;
