import { useParams } from 'react-router-dom';
import { TCountryCode } from 'shared/domain/contracts/interfaces/ICountries';

interface IUseVendoParamsResponse {
  accountId: string;
  vendorId: string;
  customerId: string;
  countryCode: TCountryCode;
  categoryId: string;
  truckId: string;
  storeId: string;
  customerInvoiceNumber: string;
  orderNumber: string;
}

type VendoParamsKeys = keyof IUseVendoParamsResponse;

export const useVendoParams = (): IUseVendoParamsResponse => {
  const params = useParams<VendoParamsKeys>() as IUseVendoParamsResponse;
  return params;
};
