import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    scrollbar-color: #dfdede #fff;
    scrollbar-width: thin;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      border: none;
      width: 8px;
      height: 8px;
    }
    
    ::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #dfdede;
      margin: 1px;
    }
  
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export default GlobalStyle;
