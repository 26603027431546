const BASE_PATH = '/vendo/:countryCode';
const COUNTRY_SELECTION = '/vendo';
const HOME_TOOLS = `${BASE_PATH}/tools`;
const ACCOUNT_SELECT = `${HOME_TOOLS}/account`;
const BALANCE_ACCOUNT_SELECT = `${HOME_TOOLS}/balance/account`;
const USER_LIST = `${ACCOUNT_SELECT}/:accountId`;
const TRANSACTION_HISTORY_LIST = `${BALANCE_ACCOUNT_SELECT}/:accountId`;
const ERROR_403 = `403`;

export const routes = {
  COUNTRY_SELECTION,
  HOME_TOOLS,
  ACCOUNT_SELECT,
  BALANCE_ACCOUNT_SELECT,
  USER_LIST,
  TRANSACTION_HISTORY_LIST,
  ERROR_403,
};
