import { TCountryLocale } from 'shared/domain/contracts/interfaces/ICountries';

interface IFormatNumberProps {
  value?: number;
  locale?: TCountryLocale;
  options?: Intl.NumberFormatOptions;
}

const formatNumberByLocale = ({ value, locale, options }: IFormatNumberProps): string => {
  if ((!value && value !== 0) || !locale) return '';
  const formattedValue = new Intl.NumberFormat(locale, options).format(value);

  return formattedValue;
};

export default formatNumberByLocale;
