import { TCountryCode } from 'shared/domain/contracts/interfaces/ICountries';

interface IProps {
  countryCode?: TCountryCode;
  countries?: string;
}

const isCountryCodeContained = ({ countryCode, countries }: IProps): boolean => {
  if (!countryCode || !countries) return false;
  const multiContractCountriesList = countries.split(',') as TCountryCode[];

  return !!multiContractCountriesList.find((code) => code === countryCode);
};

export default isCountryCodeContained;
