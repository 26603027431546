import { LoadingBuzz } from '@hexa-ui/components';
import { formatMessage } from 'shared/domain/i18n/formatters';
import { ContentContainer, LoadingSubtitle, LoadingTitle, SpinnerContainer } from './styles';

export const BeeSpinner = (): JSX.Element => {
  return (
    <SpinnerContainer>
      <ContentContainer data-testid="bee-spinner">
        <LoadingBuzz size="large" />
        <LoadingTitle>{formatMessage({ id: 'LOADING.LOADING' })}</LoadingTitle>
        <LoadingSubtitle>{formatMessage({ id: 'LOADING.ALMOST_THERE' })}</LoadingSubtitle>
      </ContentContainer>
    </SpinnerContainer>
  );
};
