const GET_BALANCE_API = '/digitalCredit/balance';
const ADD_BALANCE_API = '/operations/manual/credit';
const REMOVE_BALANCE_API = '/operations/manual/debit';
const GET_OPERATIONS_API = '/digitalCredit/operations';
const GET_MANUAL_CHANGES_API = '/digitalCredit/manual/changes';
const GET_OPERATIONS_FILTERS_API = '/digitalCredit/operations/filters';
const ACCOUNTS_SELECTION_V2 = '/v2/contracts';

export {
  ACCOUNTS_SELECTION_V2,
  ADD_BALANCE_API,
  GET_BALANCE_API,
  GET_MANUAL_CHANGES_API,
  GET_OPERATIONS_API,
  GET_OPERATIONS_FILTERS_API,
  REMOVE_BALANCE_API,
};
