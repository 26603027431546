import { Grid } from '@hexa-ui/components';
import { BookOpen, Currency, Home, User } from '@hexa-ui/icons';
import { Sidebar, useAuthenticationService, useSidebar } from 'admin-portal-shared-services';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { ROUTE_NAMES } from 'shared/domain/contracts/enums/ERoutes';
import { formatMessage } from 'shared/domain/i18n/formatters';
import { useGlobalState } from 'shared/hooks/useGlobalState';
import { useVendoNavigation } from 'shared/hooks/useVendoNavigation';
import * as singleSpa from 'single-spa';
import { useEnabledFeatures } from '../../hooks/useEnabledFeatures';
import { AppContainer } from './styles';

interface IConnectedWrapperProps {
  children: ReactNode;
}

export function ConnectedWrapper({ children }: IConnectedWrapperProps): JSX.Element {
  const { getUrl } = useVendoNavigation();
  const authenticationService = useAuthenticationService();
  const {
    state: { selectedCountry },
    setState,
  } = useGlobalState();

  const checkUserScopes = useCallback(async () => {
    const status = singleSpa.getAppStatus('@admin-portal/bees-hub-vendo-mfe');
    if (status === 'NOT_MOUNTED') {
      const supportedCountries = authenticationService.getSupportedCountries();
      if (selectedCountry && !supportedCountries.find((code) => code === selectedCountry)) {
        setState({ selectedCountry: undefined });
      }
    }
  }, [authenticationService, selectedCountry, setState]);

  const {
    featuresToggle: {
      isAccessManagementEnabled,
      isBalanceFeatureEnabled,
      isTransactionHistoryEnabled,
    },
  } = useEnabledFeatures();

  useEffect(() => {
    checkUserScopes();
  }, [checkUserScopes, selectedCountry]);

  const items = useMemo(
    () => [
      {
        title: formatMessage({ id: 'SIDEBAR.HOME_TOOLS' }),
        path: getUrl({ path: ROUTE_NAMES.HOME_TOOLS }),
        icon: () => <Home />,
        enabled: true,
        id: 'home-tools-sidebar-option',
      },
      {
        title: formatMessage({ id: 'SIDEBAR.TRANSACTION' }),
        path: '/#',
        icon: () => <BookOpen />,
        enabled: true,
        id: 'transaction-history-sidebar-option',
        hidden: !isTransactionHistoryEnabled,
      },
      {
        title: formatMessage({ id: 'SIDEBAR.BALANCE' }),
        path: getUrl({ path: ROUTE_NAMES.BALANCE_ACCOUNT_SELECT }),
        icon: () => <Currency />,
        enabled: true,
        id: 'balance-sidebar-option',
        hidden: !isBalanceFeatureEnabled,
      },
      {
        title: formatMessage({ id: 'SIDEBAR.ACCESS_MANAGEMENT' }),
        path: getUrl({ path: ROUTE_NAMES.ACCOUNT_SELECT }),
        icon: () => <User />,
        enabled: true,
        id: 'access-management-sidebar-option',
        hidden: !isAccessManagementEnabled,
      },
    ],
    [getUrl, isTransactionHistoryEnabled, isBalanceFeatureEnabled, isAccessManagementEnabled]
  );

  const enabledItems = useMemo(() => items.filter((item) => !item.hidden), [items]);

  const sidebarItems: Sidebar = useMemo(
    () => ({
      items: enabledItems,
      utils: [],
    }),
    [enabledItems]
  );

  useSidebar(sidebarItems);

  return (
    <Grid.Container sidebar type="fluid">
      <AppContainer>{children}</AppContainer>
    </Grid.Container>
  );
}
