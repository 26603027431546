import { AxiosResponse } from 'axios';
import { IVendoFilterProps } from 'features/balance/layout/components/VendoFilter/VendoFilter';
import { useEffect, useState } from 'react';
import { IBeesVendoTransaction } from 'shared/domain/contracts/interfaces/IBeesVendo';
import { useGlobalState } from 'shared/hooks/useGlobalState';
import { getOperations } from '../../services/getOperations/getOperations';

interface IGetOperationsProps {
  accountId: string;
  limit: number;
  offSet: number;
  fetchOnLoad?: boolean;
}

export interface IUseGetOperationsOutput {
  data: IBeesVendoTransaction[];
  fetch: (configFilters?: IVendoFilterProps) => void;
  externalFetch: (configFilters?: IVendoFilterProps) => Promise<AxiosResponse>;
  isError: boolean;
  isLoading: boolean;
}

export const useGetOperations = ({
  accountId,
  limit,
  offSet,
  fetchOnLoad = true,
}: IGetOperationsProps): IUseGetOperationsOutput => {
  const {
    state: { selectedCountry },
  } = useGlobalState();

  const [data, setData] = useState<IBeesVendoTransaction[]>();
  const [isLoading, setIsLoadingTo] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>();

  const initStatesBeforeFetch = () => {
    setIsError(false);
    setIsLoadingTo(true);
  };

  const fetch = (configFilters?: IVendoFilterProps) => {
    initStatesBeforeFetch();
    getOperations({
      accountId,
      country: selectedCountry,
      limit,
      offSet,
      tenantId: selectedCountry,
      filters: configFilters,
    })
      .then((res) => {
        setData(res.data.content);
      })
      .catch(() => {
        setData([]);
        setIsError(true);
      })
      .finally(() => setIsLoadingTo(false));
  };

  const externalFetch = (configFilters?: IVendoFilterProps) => {
    return getOperations({
      accountId,
      country: selectedCountry,
      limit,
      offSet,
      tenantId: selectedCountry,
      filters: configFilters,
    });
  };

  useEffect(() => {
    if (fetchOnLoad) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    fetch,
    externalFetch,
    isError,
    isLoading,
  };
};
