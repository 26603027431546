import {
  createGenerateClassName,
  createTheme,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import Router from 'config/router/Router';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { EnvConfig, EnvProvider } from 'shared/contexts/EnvProvider';
import { GlobalStateProvider } from 'shared/contexts/GlobalStateProvider';
import GlobalStyle from 'shared/layout/styles/global';
import { ConnectedWrapper } from './shared/contexts/ConnectedWrapper';
import { projectName } from './shared/domain/constants/feature-toggle';
import { initializeAnalytics, initializeRemoteConfig } from './shared/services/firebase';

const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans, sans-serif',
  },
});

const App: FC<EnvConfig> = (props) => {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-vendo-mfe',
    seed: 'bees-hub-vendo-mfe',
  });

  const { OPTIMIZELY_KEY } = props;
  createFeatureToggleServiceV2(projectName, OPTIMIZELY_KEY);
  initializeRemoteConfig();
  initializeAnalytics();

  return (
    <>
      <GlobalStyle />

      <BrowserRouter>
        <EnvProvider env={props}>
          <GlobalStateProvider>
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <ConnectedWrapper>
                  <Router />
                </ConnectedWrapper>
              </ThemeProvider>
            </StylesProvider>
          </GlobalStateProvider>
        </EnvProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
