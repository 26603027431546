import { TCountryLocale } from 'shared/domain/contracts/interfaces/ICountries';

type TCurrencySimbols = {
  [key in TCountryLocale]: string;
};

export const simbols: TCurrencySimbols = {
  'es-AR': '$',
  'es-CO': '$',
  'es-DO': 'RD$',
  'es-EC': '$',
  'es-PA': 'B/.',
  'es-PE': 'S/',
  'pt-BR': 'R$',
  'es-MX': '$',
  'en-US': '$',
};
