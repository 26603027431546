/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'admin-portal-shared-services/node_modules/axios';
import { AxiosResponse } from 'axios';

export interface IRequest {
  url: string;
  body?: any;
  config?: any;
}

const baseURL = '/api/digital-credits-business-service/';

const initialAxios = Axios.create({
  baseURL,
});

const authentication = useAuthenticationService();
const sharedAxios = authentication.enhancedAxios(initialAxios);

const Api = {
  post: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    sharedAxios.post(url, body, config),

  delete: ({ url, config }: IRequest): Promise<AxiosResponse> => sharedAxios.delete(url, config),

  get: ({ url, config }: IRequest): Promise<AxiosResponse> => sharedAxios.get(url, config),

  getV2: ({ url, config }: IRequest): Promise<AxiosResponse> => {
    const initialAxiosV2 = Axios.create({
      baseURL: '/api/v1/accounts/',
    });
    const sharedAxiosV2 = authentication.enhancedAxios(initialAxiosV2);
    return sharedAxiosV2.get(url, config);
  },

  put: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    sharedAxios.put(url, body, config),

  patch: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    sharedAxios.patch(url, body, config),
};

export default Api;
