import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { BeeSpinner } from 'shared/layout/components/BeeSpinner';
import { vendoRoutes } from './builder';

const Router = (): JSX.Element => {
  const instancedRoutes = useRoutes(vendoRoutes);

  return <Suspense fallback={<BeeSpinner />}>{instancedRoutes}</Suspense>;
};

export default Router;
