import { BalanceProvider } from 'features/balance/layout/components/BalanceProvider/BalanceProvider';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { routes } from './routes';

const {
  COUNTRY_SELECTION,
  HOME_TOOLS,
  ACCOUNT_SELECT,
  BALANCE_ACCOUNT_SELECT,
  USER_LIST,
  TRANSACTION_HISTORY_LIST,
} = routes;

const CountrySelectionPage = lazy(() => import('shared/layout/pages/CountrySelectionPage'));
const HomeToolsPage = lazy(() => import('shared/layout/pages/HomeToolsPage'));
const BeesVendoSelectionPage = lazy(
  () => import('features/access-management/layout/pages/SelectionPage')
);
const BeesVendoBalanceSelectionPage = lazy(
  () => import('features/balance/layout/pages/SelectionPage')
);
const UserListPage = lazy(() => import('features/access-management/layout/pages/UserListPage'));
const TransactionHistoryListPage = lazy(
  () => import('features/balance/layout/pages/TransactionHistory')
);
const Error403Page = lazy(() => import('shared/layout/pages/403Page'));

export const vendoRoutes: RouteObject[] = [
  { path: COUNTRY_SELECTION, element: <CountrySelectionPage /> },
  { path: HOME_TOOLS, element: <HomeToolsPage /> },
  { path: ACCOUNT_SELECT, element: <BeesVendoSelectionPage /> },
  { path: BALANCE_ACCOUNT_SELECT, element: <BeesVendoBalanceSelectionPage /> },
  { path: USER_LIST, element: <UserListPage /> },
  {
    path: TRANSACTION_HISTORY_LIST,
    element: (
      <BalanceProvider>
        <TransactionHistoryListPage />
      </BalanceProvider>
    ),
  },
  { path: '*', element: <Error403Page /> },
];
