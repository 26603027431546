import { AxiosResponse } from 'axios';
import { GET_OPERATIONS_API } from 'features/balance/domain/constants/endpoints';
import { IGetOperationsRequestParams } from 'features/balance/domain/contracts/IGetOperationsRequestParams';
import { IVendoFilterProps } from 'features/balance/layout/components/VendoFilter/VendoFilter';
import qs from 'qs';
import Api from 'shared/resources/Api';

export interface IGetOperations {
  accountId: string;
  country: string;
  limit: number;
  offSet: number;
  tenantId: string;
  filters?: IVendoFilterProps;
}
export const getOperations = async ({
  accountId,
  country,
  limit,
  offSet,
  tenantId,
  filters,
}: IGetOperations): Promise<AxiosResponse> => {
  const minAmount = filters?.filterByAmount?.minAmount ? filters?.filterByAmount?.minAmount : null;
  const maxAmount = filters?.filterByAmount?.maxAmount ? filters?.filterByAmount?.maxAmount : null;
  const startDate = filters?.filterByDate?.start
    ? removeTimefromDate(filters?.filterByDate?.start)
    : null;
  const endDate = filters?.filterByDate?.end
    ? removeTimefromDate(filters?.filterByDate?.end)
    : null;
  const params: IGetOperationsRequestParams = {
    accountId,
    limit,
    offSet,
    tenantId,
    transactionType: getTransactionTypes(filters),
    timeZone: getTimeZone(),
  };
  if (minAmount) {
    params.minAmount = minAmount;
  }

  if (maxAmount) {
    params.maxAmount = maxAmount;
  }

  if (startDate) {
    params.startDate = startDate;
  }

  if (endDate) {
    params.endDate = endDate;
  }

  return Api.get({
    url: GET_OPERATIONS_API,
    config: {
      headers: { country },
      params,
      paramsSerializer: (headerParams) => {
        return qs.stringify(headerParams, { arrayFormat: 'repeat' });
      },
    },
  });
};

const removeTimefromDate = (dateStr: string): string => {
  const splitedDate = dateStr.split('T');
  return `${splitedDate[0]}T00:00:00.000Z`;
};

const getTransactionTypes = (configFilters: IVendoFilterProps): string[] => {
  let filterTransactionType = [
    'CREDIT_BOUGHT',
    'CREDIT_BOUGHT_COMMISSION',
    'COMMISSION',
    'EXTRA_FEE',
    'UTILITY',
    'BILL_PAYMENT',
    'RECHARGE',
    'BONUS',
    'BONUS_COMMISSION',
    'GIFT_CARD',
    'GAMBLING',
    'WALLET',
    'CREDIT_BOUGHT_NEQUI_PUSH',
    'CREDIT_BOUGHT_CASH_DEPOSIT',
    'CREDIT_BOUGHT_BANCOLOMBIA_QR',
  ];
  if (configFilters && configFilters.filterByTransactionType.length > 0) {
    filterTransactionType = configFilters.filterByTransactionType;
  }
  return filterTransactionType;
};

const getTimeZone = (): number => {
  const timezoneOffset = -(new Date().getTimezoneOffset() / 60);
  return timezoneOffset;
};
