import { AxiosResponse } from 'axios';
import { GET_BALANCE_API } from 'features/balance/domain/constants/endpoints';
import { IGetBalanceRequest } from 'features/balance/domain/contracts/IGetBalanceRequest';
import Api from 'shared/resources/Api';

export const getBalance = ({
  accountId,
  country,
  countryModel = null,
}: IGetBalanceRequest): Promise<AxiosResponse> => {
  return Api.get({
    url: GET_BALANCE_API,
    config: { headers: { country, countryModel }, params: { accountId } },
  });
};
