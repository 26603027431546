import { createContext, FC, ReactNode } from 'react';
import { IGlobalState } from 'shared/domain/contracts/interfaces/IGlobalState';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

interface IGlobalStateProviderProps {
  children: ReactNode;
}

export interface IGlobalStateContext {
  state: IGlobalState;
  setState: (state: IGlobalState) => void;
}

const initialState: IGlobalStateContext = {
  state: { selectedCountry: undefined },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setState: () => {},
};

export const GlobalStateContext = createContext<IGlobalStateContext>(initialState);

export const GlobalStateProvider: FC<IGlobalStateProviderProps> = ({ children }) => {
  const [state, setState] = useLocalStorage<IGlobalState>('globalState:vendo', {
    selectedCountry: undefined,
  });

  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
