import { routes } from 'config/router/routes';
import { useNavigate } from 'react-router-dom';
import { IVendoNavigationOutput } from 'shared/domain/contracts/interfaces/navigation/IVendoNavigationOutput';
import { IVendoNavigationParams } from 'shared/domain/contracts/interfaces/navigation/IVendoNavigationParams';
import { urlHelper } from 'shared/utils/helpers';
import { useGlobalState } from './useGlobalState';

interface RouteNameAndPath {
  [key: string]: string;
}

export const useVendoNavigation = (): IVendoNavigationOutput => {
  const navigate = useNavigate();
  const {
    state: { selectedCountry },
  } = useGlobalState();

  const router = routes as RouteNameAndPath;

  const getUrl = ({ path, params }: IVendoNavigationParams): string => {
    const keys = Object.keys(routes);
    const keyValue = keys.find((key) => key === String(path));
    if (!keyValue) {
      return '';
    }

    const countryCode = selectedCountry ?? params?.countryCode;
    if (!countryCode) {
      return routes.COUNTRY_SELECTION;
    }
    return urlHelper.urlBuilder(router[keyValue], {
      countryCode,
      ...params,
    });
  };

  const navigateTo = ({ path, params, state }: IVendoNavigationParams) => {
    const url = getUrl({ path, params });
    navigate(url, { state });
  };

  return {
    navigateTo,
    getUrl,
  };
};
