import { TCountryLocale } from 'shared/domain/contracts/interfaces/ICountries';

type TDate = number | Date | string | undefined;

export enum EDateFormatType {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type TDateFormatType = EDateFormatType.MONTH | EDateFormatType.WEEK;

interface FormattedDateByLocaleProps {
  date: TDate | undefined;
  locale: TCountryLocale | undefined;
  dateFormatType: TDateFormatType | undefined;
}

const formatShortDate = (date: Date, locale = 'en-US'): string => {
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  date.setDate(day);
  date.setFullYear(year);
  date.setMonth(month);
  const shortMonthName = date.toLocaleString(locale, { month: 'short' });
  return `${day} ${shortMonthName} ${year}`;
};

const formatWeekDay = (date: Date, locale = 'en-US'): string => {
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  date.setDate(day);
  date.setFullYear(year);
  date.setMonth(month);
  const shortMonthName = date.toLocaleString(locale, { month: 'short' });
  const shortWeekName = date.toLocaleString(locale, { weekday: 'short' });
  return `${shortWeekName}, ${shortMonthName} ${day}, ${year}`;
};

const formatLongDate = (date: Date): string => {
  return date.toUTCString();
};

const isShortDate = (date: string): boolean => {
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

  return Boolean(regex.exec(date));
};

const formattedDateByLocale = ({
  date,
  locale,
  dateFormatType,
}: FormattedDateByLocaleProps): string => {
  if (!locale || !date) {
    return '';
  }
  if (dateFormatType === EDateFormatType.WEEK) {
    return formatWeekDay(new Date(date), locale);
  }

  if (isShortDate(date?.toString())) {
    return formatShortDate(new Date(date), locale);
  }

  return formatLongDate(new Date(date));
};

export default formattedDateByLocale;
