import { TCountryLocale } from 'shared/domain/contracts/interfaces/ICountries';
import { simbols } from './currencies';
import formatNumberByLocale from './formatNumberByLocale';

interface IProps {
  value?: number | null;
  locale?: TCountryLocale;
  showSimbol: boolean;
}

const formattedCurrencyByLocale = ({ value, locale, showSimbol }: IProps): string => {
  if (typeof value === 'number' && locale) {
    const formattedValue = formatNumberByLocale({
      value,
      locale,
      options: { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 },
    });
    const strFormated = showSimbol ? `${simbols[locale]}${formattedValue}` : formattedValue;
    return strFormated;
  }
  return '';
};

export default formattedCurrencyByLocale;
