import styled, { css } from 'styled-components';

interface ILoadingTitleProps {
  hidesubtitle?: boolean;
}

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled(SpinnerContainer)`
  margin: 100px;
  text-align: center;
  flex-direction: column;
`;

export const LoadingTitle = styled.span<ILoadingTitleProps>`
  margin-top: 24px;
  display: block;
  color: rgba(0, 0, 0, 0.9);
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;

  ${({ hidesubtitle }) =>
    hidesubtitle &&
    css`
      font-size: 21px;
      line-height: 26px;
      margin-top: 16px;
    `};
`;

export const LoadingSubtitle = styled.span`
  margin-top: 8px;
  display: block;
  color: rgba(0, 0, 0, 0.9);
  font-family: 'Work Sans';
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
`;
