import {
  accessManagementFlag,
  accountValidation,
  balanceFlag,
  balanceManagementBtn,
  blockPosCommissionBalance,
  country3PModel,
  transactionHistoryFlag,
} from '../domain/constants/feature-toggle';
import { useFeatureToggleWithCountry } from './useFeatureToggleWithCountry';
import { useGlobalState } from './useGlobalState';

interface IUseEnabledFeaturesResponse {
  featuresToggle: {
    isBalanceFeatureEnabled: boolean;
    isTransactionHistoryEnabled: boolean;
    isAccessManagementEnabled: boolean;
    isBlockPosCommissionBalanceEnabled: boolean;
    isAccountValidationEnabled: boolean;
    isBalanceManagementBtnEnabled: boolean;
    isCountry3PModelEnabled: boolean;
  };
  isLoading: boolean;
}

export const useEnabledFeatures = (): IUseEnabledFeaturesResponse => {
  const {
    state: { selectedCountry },
  } = useGlobalState();

  const [isBalanceFeatureEnabled, loadingBalance] = useFeatureToggleWithCountry(
    balanceFlag,
    selectedCountry
  );
  const [isTransactionHistoryEnabled, loadingTransactionHistory] = useFeatureToggleWithCountry(
    transactionHistoryFlag,
    selectedCountry
  );
  const [isAccessManagementEnabled, loadingAccessManagement] = useFeatureToggleWithCountry(
    accessManagementFlag,
    selectedCountry
  );
  const [isBlockPosCommissionBalanceEnabled, loadingBlockPostCommissionBalance] =
    useFeatureToggleWithCountry(blockPosCommissionBalance, selectedCountry);

  const [isAccountValidationEnabled, loadingAccountValidationEnabled] = useFeatureToggleWithCountry(
    accountValidation,
    selectedCountry
  );

  const [isBalanceManagementBtnEnabled, loadingBalanceManagementBtn] = useFeatureToggleWithCountry(
    balanceManagementBtn,
    selectedCountry
  );

  const [isCountry3PModelEnabled, loadingCountry3PModelEnabled] = useFeatureToggleWithCountry(
    country3PModel,
    selectedCountry
  );

  const isLoading =
    loadingBalance ||
    loadingTransactionHistory ||
    loadingAccessManagement ||
    loadingBlockPostCommissionBalance ||
    loadingAccountValidationEnabled ||
    loadingBalanceManagementBtn ||
    loadingCountry3PModelEnabled;

  return {
    featuresToggle: {
      isAccessManagementEnabled,
      isBalanceFeatureEnabled,
      isTransactionHistoryEnabled,
      isBlockPosCommissionBalanceEnabled,
      isAccountValidationEnabled,
      isBalanceManagementBtnEnabled,
      isCountry3PModelEnabled,
    },
    isLoading,
  };
};
