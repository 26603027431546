import { AxiosResponse } from 'axios';
import { IGetBalanceResponseWithI3PAccount } from 'features/balance/domain/@types/TGetBalanceResponseWithI3PAccount';
import React from 'react';
import { IBeesVendoTransaction } from 'shared/domain/contracts/interfaces/IBeesVendo';
import { IVendoFilterProps } from '../VendoFilter/VendoFilter';

interface BalanceContextType {
  balanceData: {
    posAmount: number;
    getBalanceResponse: IGetBalanceResponseWithI3PAccount;
    isBalanceLoading: boolean;
    externalFetch: () => Promise<AxiosResponse>;
  };
  operationsData: {
    operationList: IBeesVendoTransaction[];
    getOperations: (configFilters?: IVendoFilterProps) => void;
    operationsHasError: boolean;
    operationsIsLoading: boolean;
  };
}

export const BalanceContext = React.createContext<BalanceContextType | undefined>(undefined);
