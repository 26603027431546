import React, { useEffect, useState } from 'react';
import { TCountryCode } from 'shared/domain/contracts/interfaces/ICountries';
import { useGetOperations } from 'features/balance/resources/actions/useGetOperations/useGetOperations';
import { useVendoParams } from 'shared/hooks/useVendoParams';
import useGetBalance from '../../../resources/actions/useGetBalance';
import { BalanceContext } from './BalanceContext';

interface BalanceProviderProps {
  children: React.ReactNode;
}

export const BalanceProvider = ({ children }: BalanceProviderProps): React.ReactElement => {
  const { accountId, countryCode } = useVendoParams();

  const [posAmount, setPosAmount] = useState<number>(0.0);

  const {
    data: getBalanceResponse,
    isLoading: isBalanceLoading,
    externalFetch,
  } = useGetBalance({
    accountId,
    country: countryCode,
    fetchOnLoad: true,
  });

  const {
    data: operationList,
    fetch: getOperations,
    isError: operationsHasError,
    isLoading: operationsIsLoading,
  } = useGetOperations({ accountId, limit: 1000, offSet: 0 });

  useEffect(() => {
    if (getBalanceResponse) {
      const { balanceAmount, extraAmount } = getBalanceResponse;
      setPosAmount(balanceAmount - extraAmount);
    }
  }, [getBalanceResponse, isBalanceLoading]);

  const balanceData = {
    posAmount,
    getBalanceResponse,
    isBalanceLoading,
    externalFetch,
  };

  const operationsData = {
    operationList,
    getOperations,
    operationsHasError,
    operationsIsLoading,
  };

  return (
    <BalanceContext.Provider value={{ balanceData, operationsData }}>
      {children}
    </BalanceContext.Provider>
  );
};
