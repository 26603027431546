import ARFlag from '../../assets/flags/Flag_of_Argentina.svg';
import BRFlag from '../../assets/flags/Flag_of_Brazil.svg';
import COFlag from '../../assets/flags/Flag_of_Colombia.svg';
import DOFlag from '../../assets/flags/Flag_of_Dominican_Republic.svg';
import ECFlag from '../../assets/flags/Flag_of_Ecuador.svg';
import MXFlag from '../../assets/flags/Flag_of_Mexico.svg';
import PEFlag from '../../assets/flags/Flag_of_Peru.svg';

import { ICountry } from '../contracts/interfaces/ICountries';

const countriesList: ICountry[] = [
  {
    name: 'Argentina',
    code: 'AR',
    flag: ARFlag,
    locale: 'es-AR',
    languages: ['es-AR'],
    currencySymbol: '$',
  },
  {
    name: 'Brazil',
    code: 'BR',
    flag: BRFlag,
    locale: 'pt-BR',
    languages: ['pt-BR'],
    currencySymbol: 'R$',
  },
  {
    name: 'Colombia',
    code: 'CO',
    flag: COFlag,
    locale: 'es-CO',
    languages: ['es-CO'],
    currencySymbol: '$',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    flag: DOFlag,
    locale: 'es-DO',
    languages: ['es-DO'],
    currencySymbol: 'RD$',
  },
  {
    name: 'Mexico',
    code: 'MX',
    flag: MXFlag,
    locale: 'es-MX',
    languages: ['es-MX'],
    currencySymbol: '$',
  },
  {
    name: 'Peru',
    code: 'PE',
    flag: PEFlag,
    locale: 'es-PE',
    languages: ['es-PE'],
    currencySymbol: 'S/',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    flag: ECFlag,
    locale: 'es-EC',
    languages: ['es-EC'],
    currencySymbol: '$',
  },
];

export default countriesList;
