import countriesList from 'shared/domain/constants/countries';
import { TCountryCode } from 'shared/domain/contracts/interfaces/ICountries';

const isCountryMultiLanguage = (countryCode?: TCountryCode): boolean => {
  const country = countriesList.find(({ code }) => code === countryCode);
  if (!country?.languages) return false;
  return country?.languages?.length > 1;
};

export default isCountryMultiLanguage;
