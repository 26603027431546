import { getAnalytics, setUserProperties } from 'firebase/analytics';
import app from './firebase';

type Property = {
  [key: string]: string;
};

const initializeAnalytics = (): void => {
  getAnalytics(app);
};

const setUserProperty = (property: Property): void => {
  const analytics = getAnalytics(app);

  setUserProperties(analytics, property);
};

export { initializeAnalytics, setUserProperty };
