import { AxiosResponse } from 'axios';
import { IGetBalanceResponseWithI3PAccount } from 'features/balance/domain/@types/TGetBalanceResponseWithI3PAccount';
import { IGetBalanceRequest } from 'features/balance/domain/contracts/IGetBalanceRequest';
import { IGetBalanceResponse } from 'features/balance/domain/contracts/IGetBalanceResponse';
import { ECountryModel } from 'features/balance/domain/enums/ECountryModel';
import getBalance from 'features/balance/resources/services/getBalance';
import { useEffect, useState } from 'react';
import { useEnabledFeatures } from 'shared/hooks/useEnabledFeatures';

interface IUseGetBalanceOutputProps {
  data: IGetBalanceResponseWithI3PAccount;
  fetch: () => void;
  externalFetch: () => Promise<AxiosResponse>;
  setData: (data: IGetBalanceResponseWithI3PAccount) => void;
  isError: boolean;
  isLoading: boolean;
}

export const useGetBalance = ({
  accountId,
  country,
  fetchOnLoad = true,
}: IGetBalanceRequest): IUseGetBalanceOutputProps => {
  const [data, setData] = useState<IGetBalanceResponseWithI3PAccount>();
  const [isError, setIsErrorTo] = useState<boolean>(false);
  const [isLoading, setIsLoadingTo] = useState<boolean>(fetchOnLoad);
  const {
    featuresToggle: { isCountry3PModelEnabled },
    isLoading: isLoadingToggles,
  } = useEnabledFeatures();

  // eslint-disable-next-line max-params
  const handleResponse = (response, countryModel, error = false) => {
    const result = response.data as IGetBalanceResponse;
    if (countryModel === ECountryModel['3P']) {
      const account3PData = {
        accountType: !error ? ECountryModel['3P'] : ECountryModel['1P'],
        is3PCountry: true,
      };
      setData({ ...result, ...account3PData });
    } else {
      setData(result);
    }

    setIsLoadingTo(false);
  };

  const fetch = () => {
    if (isLoadingToggles) return;
    const countryModel = isCountry3PModelEnabled ? ECountryModel['3P'] : null;
    setIsLoadingTo(true);
    getBalance({ accountId, country, countryModel })
      .then((response) => handleResponse(response, countryModel))
      .catch(() => {
        getBalance({ accountId, country }).then((response) =>
          handleResponse(response, countryModel, true)
        );
      })
      .catch(() => setIsErrorTo(true));
  };

  const externalFetch = () => {
    return getBalance({ accountId, country });
  };

  useEffect(() => {
    if (fetchOnLoad || !isLoadingToggles) {
      fetch();
    }
  }, [fetchOnLoad, isLoadingToggles]);

  return {
    data,
    fetch,
    externalFetch,
    setData,
    isError,
    isLoading,
  };
};
